.loading-image {
  width: 80%;
}

.loader .loading_logo {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  display: block;
}

.loader {
  position: relative;
  width: 45px;
  height: 45px;
}

.loader::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
  border-top-color: rgba(255, 255, 255, 0.8);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (min-width: 600px) {
  .loading-image {
    max-width: 100px;
    margin: auto;
  }
}
