.shipment-address-container {
    height: calc(100vh - 100px);
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 20px;
    overflow: auto;
}

.shipment-address-container .title {
  font-size: 24px;
  text-align: left;
  margin-top: 15px;
}
.shipment-address {
  padding-top: 50px;
}

.shipment-address-split-text-input{
  display: flex;
}

.pickup-checkbox-container {
  color: white;
  padding-left: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  padding: 15px;
  padding-top: 0px;
  padding-left: 0px;
}

.shipment-address-delivery-costs {
  color: white;
  /* padding-left: 3px; */
  padding-top: 15px;
  padding-bottom: 15px;
}

.shipment-address-delivery-option {
}

.shipment-address-checkbox-container{

}

.shipment-address-container-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.swal2-icon.swal2-info {
  border-color: #fff !important;
  color: #fff !important;
}