.parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  max-width: 650px;
  width: 100%;
}
