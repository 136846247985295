.product-detail {
  display: flex;
  flex-direction: column;
  margin-top: 0px;
  margin-bottom: 0px;
}

@media screen and (max-width: 450px) {
  .product-detail-fullheight {
    margin-bottom: 76px;
  }
}
.product-detail-shop-card {
}

.product-detail-container {
  margin: 20px;
  margin-bottom: 0px;
}

.product-detail-description {
}
.product-detail-title {
  margin-top: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 19px;
  font-family: 'GothamNarrow';
  color: #ffffff;
  margin-bottom: 10px;
  width: 100%;
  margin-bottom: 20px;
}

.product-detail-text {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 19px;
  font-family: 'GothamNarrow';
  color: #ffffff;
  margin-bottom: 10px;
}

.product-detail-size-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;
  width: 100%;
  margin-bottom: 10px;
  font-family: 'GothamNarrow';
  margin-top: 30px;
}

.product-detail-size-disclaimer {
  font-style: normal;
  font-size: 12px;
  line-height: 21px;
  /* identical to box height */

  color: #ffffff;
  margin-bottom: 10px;
  font-family: 'GothamNarrow';
}

.product-detail-slider {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  max-height: calc(80vw);
  object-fit: cover;
  align-self: center;
  top: 0;
  left: 0;
}
.product-detail-slider img {
  width: 70%;
}

.awesome-slider-container {
  background-color: #bc0427;
  margin-top: 70px;
  position: relative;
  justify-content: center;
  display: flex;
}
.awssld__content {
  background-color: #00542e !important;
}

.product-detail-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 500;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-detail-footer-dots {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background-color: #669881;
}

.product-detail-footer-dots-active {
  width: 10px;
  height: 10px;
  margin: 5px;
  border-radius: 50%;
  background-color: #006638;
}
.bottom-container {
  margin-top: 20px;
}

.seperator {
  border-style: solid;
  border-width: 0.5px;
  border-color: #006638;
  width: 100%;
}
