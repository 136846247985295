.order-done {
  padding-top: 70px;
}

.order-done-text {
  margin: 20px;
  font-family: "GothamXNarrow";
  margin-top: 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;
}
