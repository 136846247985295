/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/

.StripeElement {
  padding: 10px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-section {
}

.card-section-title {
  font-size: 24px;
  margin-bottom: 15px;
  color: white;
  font-weight: bold;
}

.card-section-subtitle{
  font-size: 16px;
  margin-bottom: 20px;
  color: white;
}

.card-section-stripe {
  margin-bottom: 25px;
}

.powered-by-stripe{
  margin-top: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.powered-by-stripe img{
 height: 30px;
}

.card-section-error{
  margin-bottom: 5px;
  color: red;
  font-size: 14px;
}

.card-section-no-error{
  height: 17px;
  margin-bottom: 5px;
}