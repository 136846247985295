.shopping-card{
    height: calc(100vh - 150px);
    margin: 20px;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.shopping-card-seperator{
    border: 1px solid #006638;
}

.shopping-card-calculation{
    display: flex;
    justify-content: space-between;
}

.shopping-card-calculation-text{
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    /* identical to box height */
    
    display: flex;
    align-items: center;
    
    color: #FFFFFF;
    margin-top: 15px;
    margin-bottom: 20px;
}