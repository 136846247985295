.yellow-button {
  border-style: none;
  border-radius: 4px;
  font-family: "GothamXNarrow";
  font-weight: bold;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #000000;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #ffd42e;
  min-height: 48px;
  max-height: 50px;
}

.yellow-button:focus {
  outline: none;
  box-shadow: none;
}

@media screen and (max-width: 450px) {
  .yellow-button {
    border-style: none;
    border-radius: 4px;
    font-family: "GothamXNarrow";
    font-weight: bold;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 0.2px;
    color: #000000;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #ffd42e;
    max-height: 50px;
  }
}

@media screen and (max-width: 320px) and (max-height: 568px) {
  .yellow-button {
    font-size: 18 px;
    line-height: 1;
    width: 100%;
  }
}

.gray-button {
  border-style: none;
  border-radius: 4px;
  font-family: "GothamXNarrow";
  font-weight: bold;
  font-size: 24px;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #000000;
  width: 100%;
  cursor: pointer;
  text-transform: uppercase;
  background-color: #ffd42e;
  opacity: 0.5;
  min-height: 48px;
}

@media screen and (max-width: 450px) {
  .gray-button {
    border-style: none;
    border-radius: 4px;
    font-family: "GothamXNarrow";
    font-weight: bold;
    font-size: 18px;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: 0.2px;
    color: #000000;
    width: 100%;
    cursor: pointer;
    text-transform: uppercase;
    background-color: #ffd42e;
    opacity: 0.5;
  }
}

@media screen and (max-width: 320px) and (max-height: 568px) {
  .gray-button {
    font-size: 18 px;
    line-height: 1;
    width: 100%;
  }
}
