.shop-card {
  background-color: #bc0427;
  border-radius: 5px;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  padding: 5px;
  margin-bottom: 20px !important;
  cursor: pointer;
}
.shop-card .shop-card-header {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.shop-card .shop-card-bottom-header-title {
  padding-top: 10px;
  font-style: normal;
  font-weight: bold;
  font-family: "GothamXNarrow";
  font-size: 20px;
  line-height: 21px;
  text-align: center;
  color: white;
  max-width: 90%;
  margin-bottom: 5px;
}

.shop-card .shop-card-header-title {
  padding-top: 15px;
  font-style: normal;
  font-weight: bold;
  font-family: "GothamXNarrow";
  font-size: 24px;
  line-height: 21px;
  text-align: center;
  color: white;
  max-width: 90%;
  margin-bottom: 5px;
}

.shop-card .shop-card-header-subtitle {
  font-style: normal;
  font-family: "GothamXNarrow";
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: white;
  max-width: 80%;
}

.shop-card .shop-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop-card .shop-card-content img {
  height: 170px;
  object-fit: cover;
  max-width: 80%;
}

.custom-trikot {
  border-radius: 5px;
  position: relative;
  height: 60px;
  width: 60px;
  overflow: hidden;
}
.custom-trikot img {
  width: 60px !important;
  height: 60px !important;
  object-fit: cover;
  max-width: 100% !important;
}
.custom-trikot-circle {
}

.circle {
  border-radius: 50%;
  width: 55px;
  height: 55px;
  overflow: hidden;
  border: 2px black solid;
}

.circle img {
  margin-top: 2px;
}
