.custom-product-detail-fullheight {
  display: flex;
  flex-direction: column;
  overflow: auto;
  margin: 20px 20px 76px 20px;
}
@media screen and (max-width: 450px) {
  .custom-product-detail-fullheight {
  }
}
.product-custom-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-custom-image-container {
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  position: relative;
  max-height: 600px;
}

.product-custom-container .user-image {
  border-radius: 50%;
  width: 30%;
  height: calc(30% + 1px);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: white;
  border: 2px black solid;
  overflow: hidden;
}
.product-custom-modal-text {
  font-size: 18px;
  color: white;
  margin-bottom: 20px;
}

.product-custom-container .user-image img {
  object-fit: contain;
  border-radius: 50%;
  width: 100%;
  height: 100%;
  margin-top: 4px;
}
.product-custom-image-container img {
  object-fit: cover;
  width: 100%;
  border-radius: 5px;
  margin-bottom: -5px;
}

.product-custom-modal-cross-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.product-custom-modal-cross {
  height: 18px;
  width: 18px;
}

.cropper-container {
  position: relative;
  height: 50%;
  margin-bottom: 25px;
}

.back-container {
  width: 36px;
  height: 36px;
  cursor: pointer;
}
.back {
  width: 21px;
  height: 21px;
}
