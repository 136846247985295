.rodal > .rodal-dialog {
  max-width: 600px;
  margin: 0 auto !important;
}

.crop-container {
  position: relative;
  height: calc(100% - 15px);
  width: 100%;
  margin-bottom: 15px;
}
