.event-card {
  position: relative;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 15px;
}

.event-card:hover {
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.15);
}

.event-card-big {
  background-color: white;
  height: 250px;
  margin: 5px;
  margin-left: 0px;
  margin-right: 0px;
  border-radius: 5px;
}

.event-card-small {
  background-color: white;
  border-radius: 4px;
}

.event-card-small--gradient {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.9) 40%,
    rgba(255, 255, 255, 0) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.event-card-fullscree-image {
  width: 100%;
  min-height: 295px;
  border-radius: 5px;
  display: block;
  object-fit: cover;
}

.event-card-image {
  display: flex;
  align-items: flex-end;
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  display: block;
}

.event-location-dot {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 12px;
  left: 12px;
}

.event-location-dot-image {
  height: 20px;
  margin: 5px;
  margin-left: 0px;
}

.event-location-dot-title {
  font-family: 'GothamXNarrow';
  font-size: 15px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007234;
}

.event-location-points-fullscreen {
  position: absolute;
  top: 0px;
  right: 50px;
}

.event-location-points {
  position: absolute;
  top: 6px;
  right: 6px;
}

.event-location-points-image {
  width: 50px;
  height: 50px;
}

.event-location-points-fullscreen-image {
  width: 50px;
  height: 50px;
}

.event-card-fullscreen {
  position: absolute;
  left: 16px;
  bottom: 16px;
  right: 16px;
}

.event-card-fullscreen-text {
  width: 60%;
  font-family: 'GothamNarrow';
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #000000;
  margin-top: 4px;
}

.event-card-text {
  width: 60%;
  font-family: 'GothamNarrow';
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #000000;
  margin-top: 4px;
}

.event-card-text-white {
  color: #fff !important;
}

.event-card-tag-text {
  font-family: 'GothamNarrow';
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.3px;
  color: #000000;
  display: inline;
  background-color: #ffd42e;
  padding: 4px;
  padding-left: 10px;
}

.event-card-title {
  font-family: 'GothamXNarrow';
  font-size: 36px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  color: #ffffff;
}
@media only screen and (max-width: 400px) {
  .event-card-title {
    font-size: 28px;
  }
}

.event-card-subtitle {
  margin-top: 12px;
  margin-bottom: 20px;
  font-family: 'GothamXNarrow';
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffd42e;
  cursor: pointer;
}

.event-card-subtitle:hover {
  margin-top: 12px;
  margin-bottom: 20px;
  font-family: 'GothamXNarrow';
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #f2ca2c;
  cursor: pointer;
}
