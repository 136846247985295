.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #008a4c;
  z-index: 97;
  padding-top: env(safe-area-inset-top);
}

.header-red {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #bc0427;
  z-index: 97;
  padding-top: env(safe-area-inset-top);
}

.main-header-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 50px;
  padding: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-container-unauthorized{
  width: 100%;
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  align-items: center;
}

.header-container-unauthorized > div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.header-container-nav {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
}

.nav-link {
  color: #fff;
  font-weight: bold;
  margin: 0 15px;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
}

.header-links {
  flex: 1;
  display: flex;
}

.header-container-nav .header-links {
  flex: 1 1 50%;
  max-width: 650px;
}

.header-logo-desktop {
  flex: 1 1 25%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
}

.header-logo-desktop img {
  width: 100%;
  max-width: 140px;
}

.header-logo {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.header-logo > img {
  cursor: pointer;
  width: 100%;
  max-width: 140px;
}

.header-cart {
  flex: 1 1;
  display: flex;
  justify-content: flex-end;
}

.header-container-nav .header-cart {
  flex: 1 1 25%;
}

.header-solo {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-right-container {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  cursor: pointer;
}

.shop-cart-counter {
  position: absolute;
  font-weight: bold;
  font-size: 14px;
  color: white;
  top: -7px;
  right: -5px;
  background-color: #008a4c;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  /* text-align: center; */
  display: flex;
  justify-content: center;
}

.shop-cart-counter-text {
}

.header-right-container img {
  height: 28px;
  width: 28px;
  object-fit: contain;
}

.header-solo img {
  cursor: pointer;
}

.header-title {
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  font-family: "GothamXNarrow";
}

.header-score {
  font-size: 15px;
  line-height: 18px;
  font-weight: bold;
  color: #1a1a1a;
  font-family: "GothamXNarrow";
  background-color: #fff;
  padding: 8px 10px;
  border-radius: 10px;
  min-width: 35px;
  text-align: center;
  margin-right: 8px;
}

.desktop-header {
  width: 100%;
  height: 50px;
}

.desktop-header .inner-container {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 950px;
  margin: auto;
}

.inner-container {
  cursor: pointer;
}

.desktop-header-inner-center {
  justify-content: center !important;
}

.desktop-header .nav-items {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 70%;
  justify-content: space-evenly;
}

.desktop-header .nav-items-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  cursor: pointer;
}

.nav-items-item-active {
  color: #00542e !important;
}

.desktop-header .nav-items-item img {
  width: auto;
  height: 20px;
  margin-right: 10px;
}

.desktop-header .nav-items-item .nav-items-item-text {
  font-size: 16px;
  font-weight: bold;
  font-family: "GothamXNarrow";
}

.desktop-header .nav-items-item:hover {
  opacity: 0.8;
}

.header-collect-points {
  background-color: #00b061;
  border-radius: 100%;
  height: 30px;
  width: 30px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
}

.header-collect-points img {
  height: 12px;
  width: auto;
  display: block;
  margin: 0px;
  margin-right: 0px !important;
}
