.btn-apple {
  font-size: 18px;
  border-style: none;
  margin-bottom: 12px;
  padding: 13px;
  border-radius: 4px;
  font-weight: bold;
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  cursor: pointer;
  background-color: #000;
  
}

.apple-image {
  height: 32pt;
  width: 100%;
}
