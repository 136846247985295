.personal-space {
  min-height: calc(100vh - 126px);
  width: 100%;
  margin-top: 70px;
  margin-bottom: 56px;
  max-width: 500px;
  overflow: hidden;
}

.profile-title {
  margin-top: 28px;
  font-family: "GothamXNarrow";
  font-size: 26px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}

.personal-space-image-container {
  /* margin-top: 15px; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.personal-space-input {
  opacity: 0;
  width: 29px;
  height: 29px;
  cursor: pointer;
}

.personal-space-input-container {
  padding: 20px;
}

.personal-space-image {
  border-radius: 50%;
  background-color: #008a4c;
  width: 95px;
  height: 95px;
  object-fit: cover;
}

.personal-space-image-btn-container {
  position: absolute;
  /* right: 0px; */
  bottom: 0px;
}

.personal-space-image-btn {
  cursor: pointer;
  width: 29px;
  position: absolute;
  /* right: 0; */
  bottom: 0;
}

.personal-space-form {
  margin-top: 43px;
  width: 100%;
}

.personal-space-form-item {
  border-bottom: solid 0.5px #cbcbcb;
  margin-bottom: 29px;
}
.personal-space-form-item-edit {
  margin-bottom: 30px;
}
.personal-space-form-title {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007234;
  margin-bottom: 2px;
}

.personal-space-form-input {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 22px;
  letter-spacing: normal;
  color: #000000;
  margin-top: 5px;
}

.personal-space-error-mes {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  color: darkred;
  text-align: center;
}

.personal-space-btn-edit {
  margin-top: 30px;
  border-radius: 4px;
  background-color: #ffd42e;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  cursor: pointer;
}

.personal-space-btn-edit-disabled {
  background-color: #969696;
  opacity: 0.21;
}

.personal-space-btn-edit-text {
  font-family: "GothamXNarrow";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.1;
  letter-spacing: 0.2px;
  color: #1a1a1a;
}

.personal-space-form-title {
  font-family: "GothamXNarrow";
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007234;
  margin-bottom: 2px;
}

.personal-space-form-input-edit {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  margin-top: 4px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #cbcbcb;
  width: 94%;
}

.personal-space-form-input-edit:focus {
  opacity: 0.8;
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #000000;
  line-height: 22px;
  padding: 9px;
  padding-left: 10px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #007234;
  width: 94%;
  outline: none;
}

.delete-me-button {
  color: #d0d0d0;
  text-align: center;
  margin: 20px;
  cursor: pointer;
}
