.cardhuge-container {
  width: 100%;
  margin-bottom: 15px;
  border-radius: 5px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
  cursor: pointer;
}

.readonly {
  opacity: 0.5;
  cursor: default;
}

.cardhuge-inner-container {
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
}

@media screen and (min-width: 600px) {
  .cardhuge-container .image-container {
    height: unset;
  }
}

@media screen and (max-width: 400px) {
  .cardhuge-container .image-container img {
    height: 100%;
    width: 100%;
  }
  .cardhuge-container .cardhuge-title {
    font-size: 28px !important;
    font-weight: bold;
    line-height: 1.07;
    color: white;
    margin-bottom: 20px;
    text-align: center;
    white-space: pre;
  }
}
.cardhuge-container .image-container {
  text-align: center;
  border-radius: 5px;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.cardhuge-container .image-container img {
  width: 100%;
  border-radius: 5px;
}

.cardHuge-text-container {
  width: calc(100% - 30px);
  z-index: 50;
  bottom: 15px;
  left: 15px;
  position: absolute;
}

.cardhuge-container .cardhuge-title {
  font-family: "GothamXNarrow";
  font-size: 32px;
  font-weight: bold;
  line-height: 1.07;
  color: white;
  margin-bottom: 20px;
  text-align: center;
  white-space: pre;
}

.cardhuge-container .cardhuge-subtitle {
  font-family: "GothamNarrow";
  font-size: 18px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: normal;
  color: #ffffff;
  margin-bottom: 12px;
  margin-top: 10px;
}
