.news-card {
  position: relative;
  border-radius: 5px;
  background-color: transparent;
  cursor: pointer;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.news-card:hover {
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.15);
}

.news-card-small {
  background-color: white;
  border-radius: 4px;
}

.news-card-fullscreen-image {
  width: 100%;
  min-height: 295px;
  border-radius: 5px;
  object-fit: cover;
}

.news-card-fullscreen {
  position: absolute;
  left: 16px;
  bottom: 16px;
  right: 16px;
}

.news-card-text {
  width: 65%;
  font-family: 'GothamNarrow';
  font-size: 24px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  color: #000000;
  margin-top: 4px;
}
