.username-container {
  width: calc(100% - 40px);
  margin-right: 20px;
  margin-left: 20px;
  max-width: 400px;
  margin: auto;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.username-input-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.username-agb-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin-top: 50px;
}
.username-agb-text {
  font-family: "GothamNarrow";
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.07;
  letter-spacing: normal;
  color: #ffffff;
  margin-left: 7px;
}

.username-btn-container-disabled {
  background-color: #969696;
  opacity: 0.21;
}

.username-btn-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  /* margin-left: 24px; */
  /* margin-right: 24px; */
}
