.cap-container {
  position: relative;
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}

.underlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
}

.border-webcam {
  border: 2px solid black;
  border-radius: 50%;
  overflow: hidden;
  width: 90px;
  height: 90px;
  background-color: white;
}

.underlay-camera {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  width: 90px;
  height: 90px;
}

.close-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
  margin-top: 10px;
}

.close {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.video-container {
  position: absolute;
  top: 0;
  border-radius: 50%;
}
