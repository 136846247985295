.textslide-container {
  padding: 10px;
}

.textslide-title {
  font-family: "GothamXNarrow";
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.39;
  letter-spacing: normal;
  color: #ffffff;
  cursor: pointer;
}
.textslide-text {
  padding-top: 20px;
  font-family: "GothamNarrow";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  letter-spacing: 0px;
  color: #ffffff;
  padding: 0px;
}
.textslide-text a {
  color: #ffffff;
}
.textslide-title-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.textslide-title-img-next {
  width: 14px;
  height: 14px;
  margin-right: 15px;
}

.textslide-down {
  background-color: #008a4c;
}
