.fullscreen {
  width: 100%;
  min-height: 100%;
  background-color: #007234;
  position: relative;
  padding-top: 70px;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.headline{
  font-family: "GothamXNarrow";
  font-size: 42px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  max-width: 625px;
  padding-bottom: 5px;
  padding-left: 15px;
}

.headline:first-child {
  font-family: "GothamXNarrow";
  font-size: 42px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  max-width: 625px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-top: 10px;
}

.event-container-home{
  padding-left: 20px;
}

.fullscreen .banner {
  position: absolute;
  top: 180px;
  width: 100%;
  max-width: 100%;
}

.fullscreen-image {
  object-fit: cover;
  width: 100%;
  height: calc(100vh - 70px - 55px);
}


@media screen and (min-width: 650px) {
  .fullscreen-image {
    height: calc(100vh - 70px);
  }
}
