.order-history-card {
  background: #ffffff;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 10px;
  padding: 10px;
}
.order-history-card-header {
  display: flex;
}

.order-history-left {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.order-history-detials {
}

.order-history-status {

}
.green {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #009150;
}

.yellow {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #ffd42e;
}

.order-history-right {
  display: flex;
  flex-direction: column;
}

.order-history-number-text {
  font-size: 14px;
}
.order-history-title {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #000000;
}

.order-history-seperator {
  margin-top: 10px;
  margin-bottom: 10px;
  border: .5px solid #006638;
  border-radius: 1;
  opacity: 0.2;
}

.order-history-price {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.order-history-part{
  display: flex;
  margin: 10px;
  margin-left: 0px;
}
.order-history-part-image {
  width: 20%;
  margin-right: 10px;
  height: 60px;
}
.order-history-part-image img{
  width: 100%;
}

.order-history-part-product{
  flex-grow: 1;
}
.order-history-text{
  margin-bottom: 5px;
}
