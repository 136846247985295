.shopping-card-item {
  display: flex;
  height: 70px;
  margin-bottom: 30px;
}
.shopping-card-item-image {
  /* width: 20%; */
}
.shopping-card-information {
  margin-left: 15px;
  flex-grow: 1;
  /* margin-bottom: 21px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.shopping-card-information-title {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.shopping-card-information-size {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #ffffff;
}

.shopping-card-information-delete {
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;

  color: #ffffff;
}

.shopping-card-price {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  text-align: right;
  min-width: 85px;
  color: #ffffff;
}
