.maintenance {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.maintenance .logo {
    width: 100%;
    max-width: 300px;
    margin-bottom: 50px;
}

.maintenance .maintenance-title {
    font-family: "GothamXNarrow";
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }

  .maintenance .maintenance-text {
    color: #fff;
    max-width: 500px;
  }