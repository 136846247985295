.news-details-container {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
    position: relative;
}

.news-details-image {
    object-fit: fill;
    width: 100%;
    height: auto;
}

.news-details-info-container {
    display: flex;
}

.news-details-info-image {
    width: 16px;
    height: auto;
    object-fit: contain;
    padding-right: 15px;
}

.news-details-container-content {
    padding: 20px 30px;
    padding-top: 30px;
    padding-bottom: 100px;
}

.news-details-container-title {
    font-family: GothamXNarrow;
    font-size: 36px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 0.2px;
    color: #1e1e1e;
}

.news-details-container-description {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
    padding-top: 9px;
    padding-bottom: 9px;
    margin-bottom: 24px;
}

.news-details-container-description span {
    font-family: GothamNarrow;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #000000;
}

.news-details-container-wrapper {
    margin-top: 37px;
    margin-bottom: 30px;
}