.faq {
    padding-top: 100px;
}

.faq-title {
    margin-top: 20px;
    padding: 20px;
    font-family: "GothamXNarrow";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #ffffff;
}
.faq-subtitle {
    padding: 20px;
    opacity: 0.7;
    font-family: "GothamNarrow";
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.28;
    letter-spacing: normal;
    color: #ffffff;
}
.faq-question-title {
    font-family: "GothamXNarrow";
    font-size: 22px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.18;
    letter-spacing: normal;
    color: #ffffff;
    margin-bottom: 20px;
}

.textslide-seperator{
    border-top: 1px solid rgba(54, 52, 52, 0.4);
}

.link{
    color: #ffffff;
}